import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { DStore } from '@/interface/data/data-store';

@Component
export class GlobalMixin extends Vue {
  public lastLoading = false;

  initData() {
    this.category = '';
    this.researchTopics = {
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
    };
    this.recommendGroupId = '';
    this.retry = false;
  }

  get category() {
    return this.$store.getters['dataStore/category'];
  }
  set category(value: string) {
    this.$store.commit('dataStore/category', value);
  }

  get researchTopics() {
    return this.$store.getters['dataStore/researchTopics'];
  }
  set researchTopics(value: DStore.ResearchTopics) {
    this.$store.commit('dataStore/researchTopics', value);
  }
  get clusteringGroup() {
    return this.$store.getters['dataStore/clusteringGroup'];
  }
  set clusteringGroup(value: number) {
    this.$store.commit('dataStore/clusteringGroup', value);
  }
  get recommendProjectId() {
    return this.$store.getters['dataStore/recommendProjectId'];
  }
  set recommendProjectId(value: string) {
    this.$store.commit('dataStore/recommendProjectId', value);
  }
  get recommendGroupId() {
    return this.$store.getters['dataStore/recommendGroupId'];
  }
  set recommendGroupId(value: string) {
    this.$store.commit('dataStore/recommendGroupId', value);
  }

  get retry() {
    return this.$store.getters['dataStore/retry'];
  }
  set retry(bool: boolean) {
    this.$store.commit('dataStore/retry', bool);
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    lastLoading: boolean;
    category: string;
    clients: DStore.Clients;
    researchTopics: DStore.ResearchTopics;
    clusteringGroup: number;
    recommendProjectId: string;
    recommendGroupId: string;
    retry: boolean;
    initData(): void;
  }
}
