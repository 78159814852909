import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import AuthView from '@/views/auth/index.vue';
import store from '@/store';
Vue.use(VueRouter);
const IS_PROD = process.env.NODE_ENV === 'production';
const requireAuth =
  () => async (to: Route, from: Route, next: NavigationGuardNext) => {
    const result = await store.dispatch('authStore/verify');
    //TODO: 배포시 주석 해제
    if (!result) {
      const host = IS_PROD
        ? 'https://recommend.pmidata.co.kr'
        : 'http://127.0.0.1:8080';
      const url = encodeURIComponent([host, to.path].join(''));
      const authUrl = [host, `/api/auth/[TOKEN]?targetUrl=${url}`].join('');
      document.location.href = `https://pmidata.co.kr/?url=${authUrl}`;
    }
    return next();
  };
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '',
    redirect: '/main',
    component: () => import('../views/layout/default-layout.vue'),
    children: [
      {
        path: 'main',
        name: 'main',
        beforeEnter: requireAuth(),
        component: () => import('../views/main-view.vue'),
      },
      {
        path: '/client',
        name: 'client',
        beforeEnter: requireAuth(),
        component: () => import('../views/algorithm/client-view.vue'),
      },
      {
        path: '/chose',
        name: 'chose',
        beforeEnter: requireAuth(),
        component: () => import('../views/algorithm/chose-view.vue'),
      },
      {
        path: '/write',
        name: 'write',
        beforeEnter: requireAuth(),
        component: () => import('../views/algorithm/write-view.vue'),
      },
      {
        path: '/recommend',
        name: 'recommend',
        beforeEnter: requireAuth(),
        component: () => import('../views/result/survey-recommend-view.vue'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
  },
  {
    path: '/*',
    name: 'error404',
    component: () => import('../views/error/error-404.vue'),
  },

];
// 경로에서 제외함 /algorithm
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
