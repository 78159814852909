import Vue from 'vue';
import Vuex from 'vuex';
import userStore from '@/store/user/user-store';
import dataStore from '@/store/data/data-store';
import authStore from '@/store/auth/auth-store';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userStore: userStore,
    dataStore: dataStore,
    authStore: authStore,
  },
  plugins: [
    createPersistedState({
      paths: ['dataStore', 'userStore', 'authStore'],
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: process.env.NODE_ENV === 'production' }),
        removeItem: key => Cookies.remove(key)
      }
    }),
  ],
});
