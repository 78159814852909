
import { Component, Vue } from 'vue-property-decorator'
@Component({})
export default class AuthView extends Vue {
  get redirectUrl (): string | null {
    const url = this.$route.query.url
    if (typeof url === 'string') return url
    return null
  }

  mounted () {
    this.verify()
  }

  async verify () {
    const result = await this.$store.dispatch('authStore/verify')
    if (result) {
      await this.setClusteringGroup();

      if (this.redirectUrl) {
        window.location.href = this.redirectUrl
      } else {
        await this.$router.push({ path: '/main' })
      }
    } else {
      await this.$store.dispatch('authStore/redirect', '/login')
    }
  }

  async setClusteringGroup() {
    try {
      const { data } = await this.axios.get(`/client/group`, {
        params: {
          corpType: this.$store.getters["authStore/selectedCat1"],
          compType: this.$store.getters["authStore/selectedCat2"],
          industry: this.$store.getters["authStore/sectors"],
        },
      });
      this.clusteringGroup = data;
    } catch (e) {
      console.log(e);
    }
  }
}
