import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import { AuthStore } from '@/interface/auth/auth-store';

export default {
  namespaced: true,
  state: {
    _id: '',
    token: '',
    userId: '',
    userName: '',
    company: '',
    selectedCat1: '',
    selectedCat2: '',
    sectors: '',
  },
  getters: {
    _id: (state: AuthStore.IAuth) => state._id,
    userId: (state: AuthStore.IAuth) => state.userId,
    userName: (state: AuthStore.IAuth) => state.userName,
    company: (state: AuthStore.IAuth) => state.company,
    selectedCat1: (state: AuthStore.IAuth) => state.selectedCat1,
    selectedCat2: (state: AuthStore.IAuth) => state.selectedCat2,
    sectors: (state: AuthStore.IAuth) => state.sectors,
    token: (state: AuthStore.IAuth) => state.token,
  },
  mutations: {
    token(state: AuthStore.IAuth, token: string) {
      state.token = token;
    },
    verify(state: AuthStore.IAuth, payload: AuthStore.IAuth) {
      const {
        _id,
        company,
        sectors,
        userName,
        userId,
        selectedCat1,
        selectedCat2,
      } = payload;
      state._id = _id;
      state.company = company;
      state.sectors = sectors;
      state.userName = userName;
      state.userId = userId;
      state.selectedCat1 = selectedCat1;
      state.selectedCat2 = selectedCat2;
    },
    clear(state: AuthStore.IAuth) {
      state._id = '';
      state.token = '';
      state.userId = '';
      state.userName = '';
      state.company = '';
      state.selectedCat1 = '';
      state.selectedCat2 = '';
      state.sectors = '';
    },
  },
  actions: {
    isTokenExpired(context: ActionContext<any, any>) {
      const token = context.getters.token;
      if (!token) return true;
      return Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;
    },
    async verify(context: ActionContext<any, any>) {
      console.warn('verify');
      const isTokenExpired = await context.dispatch('isTokenExpired');
      if (isTokenExpired) return false;
      try {
        const token = context.getters.token;
        const url = 'https://pmidata.co.kr/api/user/auth-verify';
        const {
          data: { result, token: newToken },
        } = await axios.get<{ result: boolean; token: string }>(url, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        if (result && token) context.commit('token', newToken);
        return result;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
    redirect(context: ActionContext<any, any>) {
      context.commit('clear');
      document.location.href = 'https://pmidata.co.kr';
    },
    logout(context: ActionContext<any, any>) {
      context.commit('clear');
      document.location.href = 'https://pmidata.co.kr/logout';
    },
    uniSurveyRedirect(context: ActionContext<any, any>) {
      const token = context.getters.token;
      const url = 'https://unisurvey.co.kr/api/user/integrated-service-login?token=';
      window.open(`${url}${token}`);
    },
  },
};
