import { DStore } from '@/interface/data/data-store';
import { ActionContext } from 'vuex';

export interface Category {
  code: string;
  icon: string;
  name: string;
}

const dataStore = {
  namespaced: true,
  state: {
    category: '',
    researchTopics: {
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
    },
    clusteringGroup: 0,
    recommendProjectId: '',
    recommendGroupId: '',
    retry: false,
  },
  mutations: {
    category(state: DStore.DataState, value: string) {
      state.category = value;
    },
    researchTopics(state: DStore.DataState, payload: DStore.ResearchTopics) {
      //?? 확인 필요
      state.researchTopics = payload;
    },
    clusteringGroup(state: DStore.DataState, payload: number) {
      state.clusteringGroup = payload;
    },
    recommendProjectId(state: DStore.DataState, payload: string) {
      state.recommendProjectId = payload;
    },
    recommendGroupId(state: DStore.DataState, payload: string) {
      state.recommendGroupId = payload;
    },
    retry(state: DStore.DataState, payload: boolean) {
      state.retry = payload;
    },
  },
  actions: {
    category(context: ActionContext<DStore.DataState, string>, value: string) {
      context.commit('categoriesSave', value);
    },
    researchTopics(
      context: ActionContext<DStore.DataState, string>,
      payload: DStore.ResearchTopics,
    ) {
      context.commit('researchTopicsSave', payload);
    },
  },
  getters: {
    category: (state: DStore.DataState) => state.category,
    researchTopics: (state: DStore.DataState) => state.researchTopics,
    clusteringGroup: (state: DStore.DataState) => state.clusteringGroup,
    recommendProjectId: (state: DStore.DataState) => state.recommendProjectId,
    recommendGroupId: (state: DStore.DataState) => state.recommendGroupId,
    retry: (state: DStore.DataState) => state.retry,
  },
};
export default dataStore;
