import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';
import VueAxios from 'vue-axios';
import Toast from 'vue-toastification';
import { GlobalMixin } from '@/mixins/global.mixin';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueSelect from 'vue-select';
import '@/assets/css/common.css';
import '@/assets/css/custom.scss';
import 'vue-toastification/dist/index.css';
import 'vue-select/dist/vue-select.css';
Vue.config.productionTip = false;

Vue.use(Toast, { timeout: 1500, maxToasts: 5 });
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.mixin(GlobalMixin);
Vue.component('v-select', VueSelect);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
