import { UStore } from '@/interface/user/user-store';
import { ActionContext } from 'vuex';

const userStore = {
  namespaced: true,
  state: {
    //TODO: 필수 변수
    token: null,
    userName: '',
  },
  mutations: {
    //TODO: state 세팅하는 함수(동기형 state 변경할수 있는 처리기)
    setterInfo(state: UStore.InState, data: UStore.Info) {
      state.token = data.token;
    },
  },
  actions: {
    //TODO: 기능 메서드(비동기형 작업이 가능하면 mutations을 통해서 state를 변경할 수 있다.)
    async validateToken(
      context: ActionContext<UStore.InState, string>,
      payload: string,
    ): Promise<boolean> {
      //token 체크 backend 에서 확인
      let data = false;
      if (payload == 'token') data = true;
      //const { data } = { data: { token: 'test' } };
      if (data) {
        context.commit('setterInfo', data);
        return (context.state.isLogin = true);
      } else {
        return (context.state.isLogin = false);
      }
    },
    logout(context: { state: UStore.InState }): void {
      //로그아웃 기능
      context.state.token = '';
      context.state.isLogin = false;
    },
  },
  getters: {
    //TODO: page(components, views)에서 사용하는 변수 정의
    //사용 : getters['userStore/getAccessToken']
    getToken: (state: UStore.InState) => state.token,
    getIsLogin: (state: UStore.InState) => state.isLogin,
  },
};
export default userStore;
