import axios from 'axios';
import store from '@/store';
import Vue from 'vue';
import { TYPE } from 'vue-toastification';

const instance = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 1000 * 60 * 5,
});

instance.interceptors.request.use(config => {
  if (store) {
    const token = store.getters['user/token'];
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response } = error;
    const { data } = response;
    const { message } = data;

    Vue.$toast(message, {
      type: TYPE.ERROR,
    });
    return response;
  },
);

export default instance;
